import React, { useMemo } from 'react'

import { useSelector, shallowEqual } from 'react-redux'
import { uniq } from 'lodash'

import { gatherSelectedComponents } from 'components/form_builder/features/components/componentSlice'
import { Highlight } from "components/form_builder/features/components/Components"
import { DataField, DataSingleToolbar } from "components/form_builder/features/components/Components"

import {  splitComponentKey } from '../utility'

import {
  SignatureFieldSignature,
  SignatureFieldTime,
  SignatureFieldName,
  SingleSignatureWithSingleDataToolbar,
  SignatureSingleToolbar
} from "components/form_builder/features/components/Components"

/*
 * @param selected - an array of arrays. e.g. [ ['data', -1], [ 'data', 34] ]
 */

export function useGatheredToolbars() {
  const selectedKeys = useSelector(({components}) => components.selected)
  const components = useSelector(gatherSelectedComponents, shallowEqual)
  const selected = useMemo(() => selectedKeys.map(splitComponentKey), [selectedKeys])

  if (selected.length == 0)
    return

  // IF we just have one selected
  if (selected.length == 1) {
    const item = selected[0]
    const tool = Tools[item[0]]
    const Toolbar = tool.singleToolbar
    return Toolbar ? <Toolbar component={components[0]} id={item} /> : null
  }

  // If multiple are selected
  const selectedTypes = uniq(selected.map(item => item[0]))

  // We are now allowing for mixed dancing between our component types. How scandalous!
  if (selectedTypes.length > 1) {
    const multiKey = selectedTypes.sort().join("|")
    return MixedDancing[multiKey] ? MixedDancing[multiKey](selected, components) : null
  }

  const type = selectedTypes[0]
  const tool = Tools[type]
  const Toolbars = tool?.multipleSidebar
  return Toolbars ? <Toolbars components={components} ids={selected} /> : null
}

const MixedDancing = {
  'data|signature': (ids, components) => {
    const toolbars = []

    if (components.length == 2)
      toolbars.push(<SingleSignatureWithSingleDataToolbar key="1and2" ids={ids} components={components} />)

    return toolbars
  }
}

const Tools = {
  data: {
    name: 'data',
    elementType: 'button',
    component: DataField,
    singleToolbar: DataSingleToolbar,
    icon: 'fa-light fa-credit-card-blank',
    group: 'modes',
    createsComponent: true,
    disabled: false,
    defaultAttributes:{
      rect: { height: '.1', width: '.1' },
      options: {
        cursor: 'crosshair',
        behaviorInputs: { label: 'field', placeholder: 'field' },
      },
    },
    order: 1,
  },
  highlight: {
    name: 'highlight',
    elementType: 'button',
    component: Highlight,
    icon: 'fa-light fa-highlighter',
    group: 'overlay',
    createsComponent: true,
    disabled: true,
    defaultAttributes: {
      rect: { height: '1.5', width: '.1' },
      options: { color: 'purple', cursor: 'text' }
    },
    order: 1,
  },
  select: {
    name: 'select',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-arrow-pointer',
    group: 'modes',
    createsComponent: false,
    disabled: false,
    defaultAttributes:{
      options: { color: 'purple', cursor: 'default' }
    },
    order: 3,
  },
  signature: {
    name: 'signature',
    elementType: 'button',
    components: {
      'signature': SignatureFieldSignature,
      'name': SignatureFieldName,
      'time': SignatureFieldTime
    },
    singleToolbar: SignatureSingleToolbar,
    icon: 'fa-light fa-signature',
    group: 'modes',
    createsComponent: { subtype: 'signature' },
    disabled: false,
    order: 2,
    defaultAttributes: {
      rect: { height: '.1', width: '.1' },
      options: {
        cursor: 'crosshair',
        behaviorInputs: { label: 'signature', placeholder: 'signature' },
      },
    },
    dependentFields: {
      name: {
        defaultAttributes: {
          rect: { height: '.1', width: '.1' },
          options: {
            cursor: 'crosshair',
            behaviorInputs: { label: 'full name', placeholder: 'full name' },
          },
        },
      },
      time: {
        defaultAttributes: {
          rect: { height: '.1', width: '.1' },
          options: {
            cursor: 'crosshair',
            behaviorInputs: { label: 'date signed', placeholder: 'date signed' },
          },
        },
      }
    }
  },
  duplicate: {
    name: 'duplicate',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-clone',
    group: 'object-actions',
    createsComponent: true,
    disabled: false,
    order: 1,
  },
  group: {
    name: 'group',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-object-group',
    group: 'object-actions',
    order: 2,
    createsComponent: false,
    disabled: true,
  },
  align: {
    name: 'align',
    elementType: 'dropdown',
    component: () => {},
    icon: 'fa-light fa-chevron-down',
    group: 'object-actions',
    createsComponent: false,
    disabled: false,
    order: 3,
  },
  strikeout: {
    name: 'strikeout',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-strikethrough',
    group: 'overlay',
    createsComponent: true,
    disabled: true,
    order: 2,
  },
  image: {
    name: 'image',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-image',
    group: 'overlay',
    createsComponent: true,
    disabled: true,
    order: 3,
  },
  textBox: {
    name: 'text-box',
    elementType: 'button',
    component: () => {},
    icon: 'fa-light fa-text',
    group: 'overlay',
    createsComponent: true,
    disabled: true,
    order: 4,
  },
  zoom: {
    name: 'zoom',
    elementType: 'dropdown',
    component: () => {},
    icon: 'fa-light fa-chevron-down',
    group: 'view-actions',
    createsComponent: false,
    disabled: false,
    order: 1,
  }
  // Add more tools here following the same pattern
}
// Order of how they will be rendered/displayed
const groupOrder = ['modes', 'object-actions', 'overlay', 'view-actions'];

const sortedTools = []
const toolsArray = Object.values(Tools)

groupOrder.forEach((group) => {
  sortedTools.push(...toolsArray.filter(tool => tool.group === group))
})

sortedTools.sort((a, b) => a.order - b.order)

export { sortedTools, Tools }
