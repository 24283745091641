import React from 'react'
import EmailField from './EmailField'
import LoadingIcon from './LoadingIcon'
import Button from './Button'
import ArrowIcon from './ArrowIcon'
import PasswordField from './PasswordField'

const SSOCheck = (props) => {
  return (
    <>
      <div className="login">
        <EmailField
          ref={props.inputRef}
          register={props.register}
          currentStep={props.currentStep}
          errors={props.errors}
          reset={props.reset}
          isDirty={props.isDirty}
        />
        {props.isLoading ? (
          <div className="loader-spinner-container">
            <i className="fa-duotone fa-spinner fa-spin-pulse"></i>
          </div>
        ) : (
          <Button type="submit" className={`rounded-edges arrow-button ${props.isValidEmail ? 'valid' : ''}`}>
            <ArrowIcon />
          </Button>
        )}
      </div>
      <div style={{ visibility: 'hidden', height: '0px' }}>
        <PasswordField
          register={props.register}
          ref={props.inputRefPassword}
          onKeyUp={props.onKeyUp}
          updateStep={props.updateStep}
          currentStep={props.currentStep}
        />
      </div>
    </>
  )
}

export default SSOCheck
